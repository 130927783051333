body {
  color: #fff;
  background-color: #25203d;
  font-family: ui-monospace, Menlo, Monaco, Cascadia Mono, Segoe UI Mono, Roboto Mono, Oxygen Mono, Ubuntu Monospace, Source Code Pro, Fira Mono, Droid Sans Mono, Courier New, monospace;
  font-size: 16px;
}

.avatar {
  background-color: #414161;
  background-image: url("kareniel.ed5515dc.png");
  background-size: contain;
  transition: all .3s;
}

.avatar.is-empty {
  background-image: url("empty-avatar.99878dfd.png") !important;
}

.tab {
  border-bottom: .3rem solid rgba(0, 0, 0, 0);
}

.tab.is-selected {
  border-bottom: .3rem solid #6464d8;
}

.purple {
  color: #7676ff;
}

.bg-purple {
  background-color: #6464d8;
}

.container {
  width: 55rem;
}

.bg-dark {
  background-color: #38344e;
}

.bg-darkest {
  background-color: #131023;
}

.hidden {
  display: none;
}

#palette {
  max-width: 60ex;
  max-height: 20ex;
}

.green {
  color: #0f0;
}

img.emoji {
  height: 1.25em;
  width: 1.25em;
  vertical-align: -.1em;
  margin: 0 .05em 0 .1em;
}

.emoji.is-large {
  height: 2.25em;
  width: 2.25em;
}

#tab_buttons button.is-active {
  color: #aaa;
  background-color: #555;
}

.button {
  color: #fff;
  background-color: #6464d8;
  border-radius: .25rem;
  display: inline-block;
  box-shadow: 0 7px rgba(0, 0, 0, .3);
}

.button:hover {
  animation: btn .15s 50ms both;
}

@keyframes btn {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(0, 3px);
    box-shadow: 0 4px rgba(0, 0, 0, .3);
  }
}

.card {
  background-color: #38344e;
  border-radius: .25rem;
  padding: 1rem 2.5rem;
  box-shadow: 0 0 1rem rgba(0, 0, 0, .2);
}

.tag {
  color: #fff;
  background-color: #25203d;
  border-radius: .5rem;
  margin: .5rem .5rem .5rem 0;
  padding: .25rem 1rem;
  transition: all .15s;
  display: inline-block;
}

.tag.is-small {
  margin: .25rem .25rem .25rem 0;
  padding: .25rem .5rem;
  font-size: .7rem;
}

.tag:hover {
  background-color: #131023;
  transition: all .15s;
}

/*# sourceMappingURL=index.6c57c2ba.css.map */
